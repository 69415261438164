import Header from './Layout/Header/Header';
import Content from './Layout/Content/Content';
import Isi from './Layout/Isi/Isi';
import Footer from './Layout/Footer/Footer';
import { MixProvider } from "./Context/MixContext";
import { ModalProvider } from "./Context/ModalContext";
import PopupModal from "./Components/PopupModal/PopupModal";

import './Assets/webFonts/GuerbetEluciremQuiz.css'
import './App.css';

function App() {
  return (
    <div className="App">
      <MixProvider>
        <ModalProvider>
          <Header />
          <Content />
          <Isi />
          <Footer />
          <PopupModal />
        </ModalProvider>
      </MixProvider>
      
    </div>
  );
}

export default App;
