import React, { useRef, useEffect, useState } from 'react';

import './Isi.css'

const Isi = () => {

    // Is in view
    const elementRef = useRef(null);

    const openSafety = () => {
        const {current} = elementRef;
        if (current !== null){
            current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    };

    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        if (elementRef.current) {
          const rect = elementRef.current.getBoundingClientRect();
          const isVisible = (
            rect.bottom+130 <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
          );
          //console.log('top '+rect.top);
          //console.log('bottom '+rect.bottom);
          //console.log('window '+window.innerHeight);
          setIsVisible(isVisible);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
      // Initial check on component mount
      handleScroll();
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
      };
    }, []);

    return (
        <div id='isi'>
            <div id="safety-anchor" ref={elementRef} className={`${isVisible ? 'open' : 'close'}`}></div>
            <div id="safety-wrapper" className={`${isVisible ? 'open' : 'close'}`}>
                <div className="isi-container">
                    <div id="safety-tab">
                        <div className='font-18px uppercase'>
                            Important Safety Information
                        </div>
                        <div id="safety-tab-right" className='flex items-center gap-4'>
                            <div>
                                <a href="https://www.elucirem.us/media/l5rjxkbo/elucirem-uspi-1-2024-final-clean-copy.pdf" className="flex items-center gap-2 font-18px" target="_blank" title="See Full Prescribing Information">
                                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.01647 11.1032C0.86826 11.1032 0.720049 11.0465 0.607154 10.9336C0.380786 10.7072 0.380786 10.3413 0.607154 10.115L4.92031 5.80181L0.607154 1.48865C0.380786 1.26228 0.380786 0.89581 0.607154 0.67002C0.833523 0.443652 1.19942 0.443652 1.42579 0.67002L6.14826 5.39249C6.37405 5.61828 6.37405 5.98476 6.14826 6.21113L1.42579 10.9336C1.31289 11.0465 1.16468 11.1032 1.01647 11.1032Z" fill="#002F55"></path>
                                    </svg>
                                    See Full Prescribing Information
                                </a>
                            </div>
                            <div id="toggle-tab" onClick={openSafety}>
                                <div className="flex items-center gap-2 font-18px">
                                    Expand 
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" id="isi-expand" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.25 8C0.25 3.71875 3.71875 0.25 8 0.25C12.2812 0.25 15.75 3.71875 15.75 8C15.75 12.2812 12.2812 15.75 8 15.75C3.71875 15.75 0.25 12.2812 0.25 8ZM4.7375 8.90312L7 6.54375V12.25C7 12.6656 7.33437 13 7.75 13H8.25C8.66562 13 9 12.6656 9 12.25V6.54375L11.2625 8.90312C11.5531 9.20625 12.0375 9.2125 12.3344 8.91562L12.675 8.57187C12.9688 8.27812 12.9688 7.80312 12.675 7.5125L8.53125 3.36562C8.2375 3.07187 7.7625 3.07187 7.47188 3.36562L3.32188 7.5125C3.02813 7.80625 3.02813 8.28125 3.32188 8.57187L3.6625 8.91562C3.9625 9.2125 4.44688 9.20625 4.7375 8.90312Z" fill="#002F55"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                    
                <div className="isi-container">
                    <div className="isi-table">
                        <p><strong>WARNING: </strong>&nbsp;<strong>RISK ASSOCIATED WITH INTRATHECAL USE and </strong><strong>NEPHROGENIC SYSTEMIC FIBROSIS (NSF)</strong></p>
                        <p className="mb-0"><strong><u>Risk Associated with Intrathecal Use<br /></u></strong><strong>Intrathecal administration of gadolinium-based contrast agents (GBCAs) can cause serious adverse reactions including death, coma, encephalopathy, and seizures.</strong></p>
                        <p className="mt-0"><strong>ELUCIREM is not approved for intrathecal use.</strong></p>
                        <p className="mb-0"><strong><u>Nephrogenic Systemic Fibrosis <br /></u></strong><strong>GBCAs increase the risk for NSF among patients with impaired elimination of the drugs. Avoid use of ELUCIREM in these patients unless the diagnostic information is essential and not available with non-contrasted MRI or other modalities. </strong><strong>&nbsp;NSF may result in fatal or debilitating fibrosis affecting the skin, muscle and internal organs.</strong></p>
                        <p className="mb-0 mt-0 pl-4"><strong>The risk for NSF appears highest among patients with: </strong></p>
                        <p className="mb-0 mt-0 pl-4">o&nbsp; <strong>Chronic, severe kidney disease (GFR&nbsp;&lt;30 mL/min/1.73&nbsp;m<sup>2</sup>), </strong><strong>or </strong></p>
                        <p className="mb-0 mt-0 pl-4">o&nbsp; <strong>Acute kidney injury. </strong></p>
                        <p className="mb-0 mt-0"><strong>Screen patients for acute kidney injury and other conditions that may reduce renal function. For patients at risk for chronically reduced renal function (for example, age&nbsp;&gt;60&nbsp;years, hypertension or diabetes), estimate the glomerular filtration rate (GFR) through laboratory testing. </strong></p>
                        <p className="mb-0 mt-0"><strong>For patients at highest risk for NSF, do not exceed the recommended ELUCIREM dose and allow a sufficient period of time for elimination of the drug from the body prior to any re-administration.</strong></p>
                    </div>
                    <p className="mb-0"><strong>Indications and Usage</strong></p>
                    <p className="mt-0">ELUCIREM<sup>TM</sup> (gadopiclenol) injection is indicated in adult and pediatric patients aged 2 years and older for use with magnetic resonance imaging (MRI) to detect and visualize lesions with abnormal vascularity in the central nervous system (brain, spine, and associated tissues), and the body (head and neck, thorax, abdomen, pelvis, and musculoskeletal system).</p>
                    <p className="mb-0"><strong>Contraindications</strong></p>
                    <p className="mt-0">Contraindicated in patients with history of hypersensitivity reactions to ELUCIREM.</p>
                    <p className="mb-0"><strong>Warnings and Precautions</strong></p>
                    <ul className="mt-0">
                    <li><strong>Risk Associated with Intrathecal Use</strong>: Intrathecal administration of GBCAs can cause serious adverse reactions including death, coma, encephalopathy, and seizures. The safety and effectiveness of ELUCIREM have not been established with intrathecal use. ELUCIREM is not approved for intrathecal use.</li>
                    <li><strong>Nephrogenic Systemic Fibrosis: </strong>GBCAs increase the risk for NSF among patients with impaired elimination of the drugs. Avoid use of ELUCIREM among these patients unless the diagnostic information is essential and not available with non-contrast MRI or other modalities.</li>
                    <li><strong>Hypersensitivity Reactions:</strong> With GBCAs, serious hypersensitivity reactions have occurred. Before ELUCIREM administration, assess all patients for any history of a reaction to contrast media, bronchial asthma and/or allergic disorders. These patients may have an increased risk for a hypersensitivity reaction to ELUCIREM.</li>
                    <li><strong>Gadolinium Retention:</strong> Gadolinium is retained for months or years in several organs. The highest concentrations have been identified in the bone, followed by other organs (e.g. brain, skin, kidney, liver, and spleen). While clinical consequences of gadolinium retention have not been established in patients with normal renal function, certain patients might be at higher risk. These include patients requiring multiple lifetime doses, pregnant and pediatric patients, and patients with inflammatory conditions. Minimize repetitive GBCA imaging studies, particularly closely spaced studies when possible.</li>
                    <li><strong>Acute Kidney Injury:</strong> In patients with chronically reduced renal function, acute kidney injury requiring dialysis has occurred with the use of GBCAs. The risk of acute kidney injury may increase with increasing dose of the contrast agent.</li>
                    <li><strong>Extravasation and Injection Site Reactions: </strong>Injection site reactions such as injection site pain have been reported in the clinical studies with ELUCIREM. Extravasation during ELUCIREM administration may result in tissue irritation. Ensure catheter and venous patency before the injection of ELUCIREM.</li>
                    <li><strong>Interference with Visualization of Lesions Visible with Non-Contrast MRI</strong>: As with any GBCA, ELUCIREM may impair the visualization of lesions seen on non-contrast MRI.</li>
                    </ul>
                    <p className="mb-0"><strong>Adverse Reactions:</strong></p>
                    <p className="mt-0">In clinical trials, the most frequent adverse reactions that occurred in &gt; 0.2% of patients who received ELUCIREM included: injection site pain, headache, nausea, injection site warmth, injection site coldness, dizziness, and localized swelling.</p>
                    <p className="mb-0"><strong>Use in Specific Populations</strong></p>
                    <ul className="mt-0">
                    <li><strong>Pregnancy:</strong> GBCAs cross the human placenta and result in fetal exposure and gadolinium retention. There are no available data on ELUCIREM use in pregnant women to evaluate for a drug-associated risk of major birth defects, miscarriage or other adverse maternal or fetal outcomes.</li>
                    <li><strong>Lactation:</strong> There are no data on the presence of ELUCIREM in human milk, the effects on the breastfed infant, or the effects on milk production. However, published lactation data on other GBCAs indicate that 0.01 to 0.04% of the maternal gadolinium dose is excreted in breast milk.</li>
                    <li><strong>Pediatric Use:</strong> The safety and effectiveness of ELUCIREM have not been established in pediatric patients younger than 2 years of age.</li>
                    <li><strong>Geriatric Use: </strong>This drug is known to be substantially excreted by the kidney, and the risk of adverse reactions to this drug may be greater in patients with impaired renal function.</li>
                    <li><strong>Renal Impairment:</strong> In patients with renal impairment, the exposure of gadopiclenol is increased compared to patients with normal renal function. This may increase the risk of adverse reactions such as NSF. No dose adjustment of ELUCIREM is recommended for patients with renal impairment.</li>
                    </ul>
                    <p>You are encouraged to report negative side effects of prescription drugs to the FDA. Visit&nbsp;www.fda.gov/medwatch or call 1-800-FDA-1088.</p>
                    <p><strong>Please see the full Prescribing Information, including the Medication Guide, for additional important safety information.</strong></p>
                </div>
            </div>
            <div id="safety-spacer" className={`${isVisible ? 'open' : 'close'}`}></div>
        </div>
    );
};

export default Isi;