import React, { useState } from 'react';

import './Header.css'

const Header = () => {

    const [scrollPos, setScrollPos] = useState(0);


    window.addEventListener('scroll', () => {
        setScrollPos(window.scrollY);
    });

    return (
        <header id="header" className={scrollPos > 5 ? "solid-head" : "" }>
            <div className="header-wrap">
                <div className="logo">
                    <img src={require(`../../Assets/Images/logo.png`)} height="100%" alt="logo" />
                </div>
            </div>
        </header>
    );
};

export default Header;