import React from 'react';  

import './Footer.css'

const Footer = () => {
    return (
        <footer className="footer-dark">
            <div className="footer-wrap">
                <div className="footer-footnote">
                    <div className="footer-logo">
                        <img src={require("../../Assets/Images/guerbet-logo.png")} width="192" alt="Guerbet logo" />
                    </div>
                    <div class="footer-text"><p class="jobcode">GU12230170</p></div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;