import React, { useEffect, useState } from 'react';
import { useZoomFactor, useZoomFactorUpdate } from "../../Context/MixContext";
import { useModal, useModalUpdate } from "../../Context/ModalContext";
import { motion, stagger } from "framer-motion"

import './Content.css';

import weightIcon from "../../Assets/Images/weight-icon.svg";
import volumeIcon from "../../Assets/Images/volume-icon.svg";
import qualitycon from "../../Assets/Images/quantity-icon.svg";


const Content = () => {

    const dose = 0.05;
    const concentration = 0.5;
    const paediatric = 50;
    const qogvalue = 78.6;

    const [volumeInjected, setVolumeInjected] = useState(0);
    const [juvenile, setJuvenile] = useState(true);
    const [roundVal, setRoundVal] = useState(0);
    const [calculatedVolume, setCalculatedVolume] = useState(0);
    const [lbsToKilo, setLbsToKilo] = useState(0);

    // Zoom
    const getZoom = useZoomFactor();
    const setZoom = useZoomFactorUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    const [getWeightMode, setWeightMode] = useState(true);
    const [isVisible, setIsVisible] = useState(false);

    window.addEventListener('resize', () => {
        const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
        setZoom(browserZoomLevel);
    })

    useEffect(() => {
        document.getElementById("weight-input").focus();
        window.scrollTo(0, 2);
    }, []);

    const handleClick = (e, _mode) => {
        setWeightMode(_mode);
        const weightType = document.getElementById("weight-type");
        weightType.classList.remove("display-weight-type");
        setIsVisible(false);
        reset();
    }

    const handleInput = (e) => {
        let inputValue = e.target.value;
        // Remove non-numeric characters except decimal point
        inputValue = inputValue.replace(/[^0-9.]/g, '');
        console.log(inputValue)
        // Allow only one decimal point
        const decimalCount = inputValue.split('.').length - 1;
        if (decimalCount > 1) {
            const parts = inputValue.split('.');
            inputValue = parts[0] + '.' + parts.slice(1).join('');
        }
        // Update the input value
        e.target.value = inputValue;
        if ( !getWeightMode ) {
            inputValue = lbsToKilos(e.target.value);
        } else {
            inputValue = e.target.value;
        }
            setRoundVal(Math.floor(inputValue) + (inputValue % 1 >= 0.5 ? 1 : 0));
            setCalculatedVolume( (dose * (Math.floor(inputValue) + (inputValue % 1 >= 0.5 ? 1 : 0))) / concentration );
    }

    function lbsToKilos(lbs) {
        setLbsToKilo(lbs * 0.453592)
        return lbs * 0.453592;
    }

    const equation = (_dose, _weight) => {
        return _dose * _weight;
    }

    const toggleWeightMode = (e) => {
        const weightMode = document.getElementById("weightmode");
        if ( weightMode.classList.contains("select-rotate") ) {
            weightMode.classList.add("select-unrotate");
            weightMode.classList.remove("select-rotate");
        } else {
            weightMode.classList.add("select-rotate");
            weightMode.classList.remove("select-unrotate");
        }
        setIsVisible(!isVisible);
    }
    const openModal = (e) => {
        popupModalSet(true);
    }
    const reset = (e) => {
        const input = document.getElementById("weight-input");
        input.value = "";
        setVolumeInjected(0);
        setJuvenile(true);
        setRoundVal(0);
        setCalculatedVolume(0);
        window.scrollTo(0, 0);
    }
    const openSOPC = (e) => { 
        window.open('https://www.elucirem-global.com/media/x41o4k41/mentions-l%C3%A9gales-abr%C3%A9g%C3%A9es-corporate-elucirem.pdf', '_blank', 'noopener,noreferrer')
    }

    
    
  

    return (
        <motion.div className="main-wrapper" initial={{ opacity: 0}} animate={{ opacity: 1 }} transition={{ duration: 2 }}>
            <div className="content">
                <div className="text1">
                    <h1>ELUCIREM<sup>™</sup> dose calculator</h1>
                    <h2>ELUCIREM<sup>™</sup> is injected at a concentration of 0.5 M and the approved <span className="adaptive-break"><br/></span>dose is 0.05 mmol/kg actual body weight (equivalent to 0.1 ml/kg).<sup>1</sup></h2>
                </div>
                <div className="calculate-wrap">
                    <div className="calculate-holder">
                        <motion.div initial={{ opacity: 0, x:100 }} animate={{ opacity: 1, x:0 }} transition={{ duration: 0.3, delay: .2, ease: "easeInOut" }}>
                            <div className="calculate weight">
                                <div className="label-wrap">
                                    <div className="icon">
                                        <img src={weightIcon} alt="Patient’s weight" />
                                    </div>
                                    <div className="label"><p>Patient’s weight</p></div>
                                </div>
                                <div className="input weight-input">
                                    <input id="weight-input" type="text" name="weightInput" onChange={ (e) => handleInput(e) } />
                                    <p>{ getWeightMode ? "Kg" : "Lbs" }</p>
                                    <div id="weightmode" className="weight-type-select" onClick={(e) => toggleWeightMode(e)}></div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div initial={{ opacity: 0 }}animate={{ opacity: isVisible ? 1 : 0 }}>
                            <div id="weight-type" className="weight-type">
                                <div className="unit"><p className={ getWeightMode ? "selected" : "" } onClick={(e) => handleClick(e, true)}>Kilo</p></div>
                                <div className="unit"><p className={ getWeightMode ? "" : "selected" } onClick={(e) => handleClick(e, false)}>Lbs</p></div>
                            </div>
                        </motion.div>
                    </div>
                    <motion.div initial={{ opacity: 0, x:100 }} animate={{ opacity: 1, x:0 }} transition={{ duration: 0.3, delay: .4, ease: "easeInOut" }}>
                        <div className="calculate volume">
                            <div className="label-wrap">
                                <div className="icon">
                                    <img src={volumeIcon} alt="Volume of ELUCIREM™ to be injected" />
                                </div>
                                <div className="label"><p>Volume of ELUCIREM<sup>™</sup> to be injected</p></div>
                            </div>
                            <div id="volume-injected" className="input">
                                <p>{  isNaN(volumeInjected) ? 0 : calculatedVolume.toFixed(1) } ml</p>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, x:100 }} animate={{ opacity: 1, x:0 }} transition={{ duration: 0.3, delay: .6, ease: "easeInOut" }}>
                        <div className="calculate quantity">
                            <div className="label-wrap">
                                <div className="icon">
                                    <img src={qualitycon} alt="Quantity of gadolinium saved" />
                                </div>
                                <div className="label"><p>Quantity of gadolinium saved<sup>*</sup></p></div>
                            </div>
                            <div id="Quantity-saved" className="input">
                                <p>{  (qogvalue * (isNaN(volumeInjected) ? 0 : calculatedVolume.toFixed(1))).toFixed(1) } mg</p>
                            </div>
                        </div>
                    </motion.div>
                </div>

                <div className="text2">
                    <h3>ELUCIREM<sup>™</sup>: designed to reveal high-quality images <span className="adaptive-break"><br/></span>at half the conventional dose of gadolinium<sup>1</sup></h3>
                    <div className="link-wrap">
                        <p className="links" onClick={(e) => reset(e)}>Reset</p>
                        <p className="links" onClick={(e) => openModal(e)}>See calculation</p>
                    </div>
                    <p className="footnote pb-4">This Dose Calculator is intended for use only by qualified healthcare providers and does not replace professional judgment or clinical experience. All calculations should be confirmed before use.</p>
                    <p className="footnote"><sup>*</sup>Compared with conventional gadolinium-based contrast agents. <strong>1.</strong> ELUCIREM [package insert]. Princeton, NJ: Guerbet LLC; 2024.</p>
                    <p className="footnote"><span className="foot-link" onClick={(e) => openSOPC(e)}>Click here</span> to see full Prescribing Information.</p>
                    
                </div>
                <div className="debug">
                    <p><strong>Juvenile:</strong> { juvenile ? "Yes" : "No" }</p>
                    <p><strong>Dose:</strong> { dose }</p>
                    <p><strong>Concentration:</strong> { concentration }</p>
                    <p><strong>Rounded value:</strong> { roundVal }</p>
                    <p><strong>Calculated volume:</strong> { calculatedVolume }</p>
                    <p><strong>Lbs to Kilos:</strong> { lbsToKilo }</p>
                </div>
            </div>
        </motion.div>
    );
};

export default Content;